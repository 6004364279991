* {
  box-sizing: border-box;
}
html {
  background: #141517;
  background: linear-gradient(135deg, rgba(11,0,28,1) 0%, rgba(21,19,48,1) 40%, rgba(35,17,43,1) 60%, rgba(11,0,28,1) 100%);
  background-attachment: fixed;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}
html, body, #root, .app {
  width: 100%;
  height: 100%;
  margin: 0;
}